// fonts: [
//     "Montserrat:400, 700",
//     "Open Sans:400, 700",
//  ],

const typography = {
  fontFamily: ['Montserrat', 'Open Sans', 'sans-serif'].join(','),
  h1: {
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: '2.125rem'
  },
  h2: {
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: '1.875rem'
  },
  h3: {
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: '1.25rem'
  },
  h4: {
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: '1.25rem'
  },
  h5: {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '1.25rem'
  },
  h6: {
    fontFamily: 'Open Sans',
    fontWeight: 700,
    fontSize: '1.125rem'
  },
  body1: {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '1rem'
  },
  body2: {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '0.875rem'
  },
  button: {
    fontFamily: 'Montserrat',
    fontWeight: 700
  },
  caption: {
    fontFamily: 'Montserrat'
  }
};

export default typography;
