import React from 'react';
import Image from 'next/image';
import { Box, makeStyles, Typography, Button } from '@material-ui/core';

import { CartProductWQuantity } from 'domain/types';
import CustomA from 'components/ui/links/CustomA';
import QuantityControls from './QuantityControls';
import { scrollBarStyles } from 'theme/scrollBar';
import classNames from 'classnames';
import events from 'utils/helpers/analytics/events';
import PriceText from 'components/ui/textFormaters/PriceText';
import { calculatePrice, calculatePriceWithQuantity } from 'utils/helpers/products/calculatePrice';

interface CartProductProps {
  product: CartProductWQuantity;
  addToCart: (id: string) => void;
  substractFromCart: (id: string) => void;
  removeFromCart: (id: string) => void;
}

const CartProductComponent: React.FC<CartProductProps> = ({
  product: {
    name,
    sys: { id },
    mainImage: { url: image },
    category: { slug: categorySlug, name: categoryName },
    slug,
    price,
    discount,
    quantity,
    available,
    color
  },
  addToCart,
  substractFromCart,
  removeFromCart
}): JSX.Element => {
  const classes = useStyles();

  const onDecrease = () => {
    substractFromCart(id);

    events.removeFromCart(categoryName, name, color, 1);
  };

  const onIncrease = () => {
    addToCart(id);

    events.addToCart(categoryName, name, color, 1, calculatePrice(price, discount));
  };

  const onRemove = () => {
    removeFromCart(id);

    events.removeFromCart(categoryName, name, color, quantity);
  };

  return (
    <Box className={classes.container}>
      <Image
        width={150}
        height={150}
        src={image}
        className={classNames(classes.image, !available && classes.imageNotAvailable)}
      />

      <Box className={classes.content}>
        <CustomA href={`/shop/${categorySlug}/${slug}`}>
          <Typography variant="h3" className={classes.title}>
            {name}
          </Typography>
        </CustomA>

        <Typography variant="h5" component="p" color="primary" className={classes.price}>
          <PriceText price={price} discount={discount} isAvailable={!!available} />
        </Typography>

        {quantity > 1 && (
          <Typography variant="h5" component="p" color="primary" className={classes.price}>
            Всього: {calculatePriceWithQuantity(price, discount, quantity)} грн
          </Typography>
        )}

        <Box className={classes.controls}>
          {available ? (
            <QuantityControls
              quantity={quantity}
              onDecrease={onDecrease}
              onIncrease={onIncrease}
              available={available}
            />
          ) : (
            <Typography variant="h5" component="p" color="secondary">
              Не в наявності
            </Typography>
          )}

          <Button onClick={onRemove} variant="text" className={classes.deleteButton}>
            <Typography
              variant="body1"
              component="p"
              color="textSecondary"
              className={classes.deleteText}
            >
              Видалити
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100vw',
    maxWidth: '100%',
    gap: theme.spacing(2.5),
    marginBottom: theme.spacing(7)
  },
  image: {
    width: `calc(50% - ${theme.spacing(0.5)}px)`
  },
  imageNotAvailable: {
    opacity: 0.5
  },
  content: {
    width: `calc(50% - ${theme.spacing(0.5)}px)`,
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexGrow: 1,
    ...scrollBarStyles.horizontal
  },
  title: {
    marginBottom: theme.spacing(2),
    textTransform: 'uppercase'
  },
  price: {
    marginBottom: theme.spacing(2.5)
  },
  controls: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    width: '100%',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  deleteButton: {
    minWidth: 'unset',
    padding: 0
  },
  deleteText: {
    textDecoration: 'underline'
  }
}));

export default CartProductComponent;
