import { APP_ENV } from './APP_ENV';

const isProd = APP_ENV === 'production';

export const REVALIDATES = {
  index: isProd ? 600 : 30,
  checkout: 86400, // 1 day
  shop: isProd ? 120 : 30,
  category: isProd ? 120 : 30,
  product: isProd ? 120 : 30,
  feedback: isProd ? 3600 : 30
};
