import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';

interface TotalPriceProps {
  price: number;
}

const TotalPrice: React.FC<TotalPriceProps> = ({ price }): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography variant="h5">Всього:</Typography>

      <Typography variant="h5">{price} грн</Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(3)
  }
}));

export default TotalPrice;
