import React from 'react';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';

interface CustomAProps {
  children: React.ReactNode;
  className?: string;
  href?: string;
  target?: string;
  rel?: string;
  onClick?: () => void;
  title?: string;
}

const CustomA = React.forwardRef<HTMLAnchorElement, CustomAProps>(
  ({ children, className, rel, target, onClick, href, title }, ref): JSX.Element => {
    const classes = useStyles();

    return (
      <a
        className={classnames(classes.link, className)}
        rel={rel}
        target={target}
        ref={ref}
        href={href}
        onClick={onClick}
        title={title}
      >
        {children}
      </a>
    );
  }
);

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    ['&:hover']: {
      textDecoration: 'none',
      cursor: 'pointer'
    }
  }
}));

CustomA.displayName = 'CustomA';

export default CustomA;
