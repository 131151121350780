import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

interface CartModalActionsProps {
  onClose: () => void;
  onCheckout: () => void;
  disabledMessage: string;
}

const CartModalActions: React.FC<CartModalActionsProps> = ({
  onClose,
  onCheckout,
  disabledMessage
}) => {
  const classes = useStyles();

  return (
    <>
      <Button
        onClick={onCheckout}
        color="primary"
        variant="contained"
        fullWidth
        disabled={!!disabledMessage}
      >
        {disabledMessage || 'Оформити замовлення'}
      </Button>

      <Button onClick={onClose} className={classes.backButton} fullWidth>
        Продовжити покупки
      </Button>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  backButton: {
    color: theme.palette.text.secondary,
    textDecoration: 'underline !important'
  }
}));

export default CartModalActions;
