import { useMemo } from 'react';
import { ApolloError, ApolloQueryResult, gql, useQuery, useReactiveVar } from '@apollo/client';

import { CartProduct, CartProductWQuantity, ColoredCartProduct } from 'domain/types';
import { cartVar } from 'utils/apollo/vars/cartVar';
import { GET_CART_PRODUCTS } from 'utils/apollo/queries/GET_CART_PRODUCTS';

type CartQueryResponse = {
  productCollection: {
    items: CartProduct[];
  };
  coloredProductCollection: {
    items: ColoredCartProduct[];
  };
};

type CartProductsHookResult = {
  loading: boolean;
  error: ApolloError | undefined;
  products: CartProductWQuantity[];
  refetch: () => Promise<ApolloQueryResult<CartQueryResponse>>;
};

export const useCartProducts = (): CartProductsHookResult => {
  const cartItems = useReactiveVar(cartVar);

  const { loading, error, data, refetch } = useQuery<CartQueryResponse>(gql(GET_CART_PRODUCTS), {
    variables: {
      productCollectionWhere: {
        sys: {
          id_in: cartItems.map(({ id }) => id)
        }
      },
      coloredProductCollectionWhere: {
        sys: {
          id_in: cartItems.map(({ id }) => id)
        }
      },
      productCollectionLimit: 1
    },
    ssr: false,
    skip: cartItems.length === 0
  });

  const productsNormalized = useMemo(
    () =>
      data
        ? [
            ...transformColoredProducts(data.coloredProductCollection.items),
            ...data.productCollection.items
          ]
        : [],
    [data]
  );

  const productsWithQuantity = useMemo(
    () =>
      productsNormalized.map((product) => ({
        ...product,
        quantity: cartItems.find(({ id }) => id === product.sys.id)?.quantity || 0
      })),
    [productsNormalized, cartItems]
  );

  return {
    loading,
    error,
    products: productsWithQuantity,
    refetch
  };
};

const transformColoredProducts = (coloredProducts: ColoredCartProduct[]): CartProduct[] =>
  coloredProducts.flatMap(transformColored);

const transformColored = ({
  color,
  sys,
  mainImage,
  available,
  linkedFrom: {
    productCollection: {
      items: [product]
    }
  }
}: ColoredCartProduct): CartProduct[] => {
  if (product) {
    return [
      {
        sys,
        name: product.name,
        price: product.price,
        slug: `${product.slug}?color=${color}`,
        category: product.category,
        mainImage,
        available,
        color,
        discount: product.discount
      }
    ];
  }

  return [];
};
