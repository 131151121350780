export const MIN_ORDER_PRICE = 250;
export const MIN_CASH_ON_DELIVERY_PRICE = 500;
export const ADVANCED_PAYMENT_FOR_CASH_ON_DELIVERY = 250;

export const ORDER_STEPS = [
  { id: '1', name: 'ЗАМОВЛЕННЯ ОФОРМЛЕНО', responsible: 'Автоматично' },
  { id: '2', name: 'ЗАМОВЛЕННЯ ПІДТВЕРДЖЕНО', responsible: 'Адміністратор' },
  { id: '3', name: 'ОПЛАТА ПІДТВЕРДЖЕНА', responsible: 'Адміністратор' },
  { id: '4', name: 'ЗАМОВЛЕННЯ ВІДПРАВЛЕНО', responsible: 'Адміністратор' },
  { id: '5', name: 'ЗАМОВЛЕННЯ ОТРИМАНО', responsible: 'Клієнт' }
];

export const ORDER_CANCELED_STEP = {
  id: '6',
  name: 'ЗАМОВЛЕННЯ СКАСОВАНО',
  canceled: true,
  responsible: 'Адміністратор'
};

export const ORDER_CANCELED_STEPS = [...ORDER_STEPS, ORDER_CANCELED_STEP];
