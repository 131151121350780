export const GET_CART_PRODUCTS = `
  query CartProductsQuery(
    $productCollectionWhere: ProductFilter
    $coloredProductCollectionWhere: ColoredProductFilter
    $productCollectionLimit: Int
  ) {
    productCollection(where: $productCollectionWhere) {
      items {
        sys {
          id
        }
        name
        slug
        price
        discount
        available
        mainImage {
          url
        }
        category {
          slug
          name
        }
      }
    }
    coloredProductCollection(where: $coloredProductCollectionWhere) {
      items {
        sys {
          id
        }
        color
        available
        mainImage {
          url
        }
        linkedFrom {
          productCollection(limit: $productCollectionLimit) {
            items {
              sys {
                id
              }
              name
              slug
              price
              discount
              available
              category {
                slug
                name
              }
            }
          }
        }
      }
    }
  }
`;
