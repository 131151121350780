const palette = {
  common: {
    black: '#000000',
    white: '#ffffff'
  },

  primary: {
    light: '#9CDBE0',
    main: '#5AB6BD'
  },
  secondary: {
    light: '#FCC6DD',
    main: '#D882A7'
  },
  info: {
    light: '#B3BFF1',
    main: '#7280BA'
  },
  warning: {
    light: '#FEE686',
    main: '#F0B261'
  },
  error: {
    light: '#F3CABF',
    main: '#E48E77'
  },
  success: {
    light: '#C0E8DD',
    main: '#78B7A6',
    dark: '#224222'
  },
  background: {
    default: 'fff'
  },

  divider: '#ccc',

  text: {
    primary: '#000000',
    secondary: '#707070'
  }
};

export const extraPalette = {
  grey: {
    lightest: '#f6f7f8',
    lighter: '#dbdada',
    light: '#9D9D9C',
    main: '#707070'
  }
};

export default palette;
