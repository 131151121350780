import { Overrides } from '@material-ui/core/styles/overrides';

import palette, { extraPalette } from './palette';

const overrides: Overrides = {
  MuiPaper: {
    rounded: {
      borderRadius: 7
    }
  },
  MuiTextField: {
    root: {
      width: '100%',
      maxWidth: 350
    }
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: 2,
      '&:hover .MuiOutlinedInput-notchedOutline :not(.MuiOutlinedInput-focused)': {
        borderColor: extraPalette.grey.main
      }
    }
  },
  MuiButton: {
    containedPrimary: {
      color: palette.common!.white
    },
    containedSecondary: {
      color: palette.common!.white
    },
    contained: {
      boxShadow: 'none',
      borderRadius: 0,
      height: 40
    },
    containedSizeLarge: {
      height: 47,
      paddingLeft: 50,
      paddingRight: 50,
      fontSize: '1.25rem'
    },
    outlined: {
      borderRadius: 1
    },
    outlinedSizeLarge: {
      height: 47,
      paddingLeft: 50,
      paddingRight: 50,
      fontSize: '1.25rem'
    }
  },
  MuiStepLabel: {
    root: {
      fontWeight: 700
    },
    label: {
      textTransform: 'uppercase',
      color: extraPalette.grey.lighter,
      fontSize: '1.25rem',
      fontWeight: 700
    },
    completed: {
      color: `${palette.primary.main} !important`,
      fontWeight: 700
    },
    active: {
      color: `${palette.primary.main} !important`
    },
    iconContainer: {
      paddingRight: 16
    }
  },
  MuiStepIcon: {
    root: {
      color: palette.common.white,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: extraPalette.grey.lighter,
      borderRadius: '50%'
    },
    active: {
      borderWidth: 0
    },
    text: {
      fill: palette.common!.white
    },
    completed: {
      borderWidth: 0
    }
  },
  MuiStepContent: {
    root: {
      marginTop: 16,
      paddingLeft: 28
    }
  }
};

export default overrides;
