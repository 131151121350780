import React from 'react';
import {
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  IconButton,
  Typography,
  Box
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { extraPalette } from 'theme/palette';
import { scrollBarStyles } from 'theme/scrollBar';

const Transition = React.forwardRef(function Transition(props: any, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface ModalProps {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  title?: string;
  actionsComponent?: React.ReactNode;
  subtitle?: string;
}

const Modal: React.FC<ModalProps> = ({
  onClose,
  open,
  children,
  title,
  actionsComponent,
  subtitle = ''
}): JSX.Element => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      BackdropProps={{
        classes: {
          root: classes.backdrop
        }
      }}
      PaperProps={{
        classes: {
          root: classes.paper
        }
      }}
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody
      }}
      transitionDuration={500}
      TransitionComponent={Transition as any}
    >
      {!!title && (
        <Box className={classes.titleContainer}>
          <Typography variant="h2" className={classes.title}>
            {title}
          </Typography>

          <IconButton aria-label="close-modal" className={classes.closeButton} onClick={onClose}>
            <Close fontSize="large" />
          </IconButton>

          {subtitle && (
            <Typography variant="h4" className={classes.subtitle} color="textSecondary">
              {subtitle}
            </Typography>
          )}
        </Box>
      )}

      <DialogContent className={classes.contentContainer}>{children}</DialogContent>

      {!!actionsComponent && (
        <DialogActions
          className={classes.actionsContainer}
          classes={{ spacing: classes.actionsSpacing }}
        >
          {actionsComponent}
        </DialogActions>
      )}
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  topScrollPaper: {
    alignItems: 'flex-start',
    justifyContent: 'flex-end'
  },
  topPaperScrollBody: {
    verticalAlign: 'top'
  },
  backdrop: {
    backgroundColor: `${extraPalette.grey.main} !important`,
    opacity: `0.2 !important`
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    borderRadius: 0,
    maxHeight: '100%'
  },
  titleContainer: {
    padding: 0,
    paddingTop: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(7.25)
    }
  },
  title: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textTransform: 'uppercase',
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(3),
      paddingLeft: theme.spacing(4.5),
      paddingRight: theme.spacing(4.5)
    }
  },
  subtitle: {
    color: extraPalette.grey.light,
    borderTopWidth: 2,
    borderTopColor: extraPalette.grey.lighter,
    borderTopStyle: 'solid',
    borderBottomWidth: 2,
    borderBottomColor: extraPalette.grey.lighter,
    borderBottomStyle: 'solid',
    padding: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(3),
      paddingLeft: theme.spacing(4.5),
      paddingRight: theme.spacing(4.5)
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(3.5),
    color: theme.palette.common.black,
    [theme.breakpoints.up('sm')]: {
      right: theme.spacing(2.5),
      top: theme.spacing(5.5)
    }
  },
  contentContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    ...scrollBarStyles.vertical,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(4.5),
      paddingRight: theme.spacing(4.5)
    }
  },
  actionsContainer: {
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(4.5),
      paddingRight: theme.spacing(4.5)
    }
  },
  actionsSpacing: {
    '& > :not(:first-child)': {
      marginLeft: 'unset'
    }
  }
}));

export default Modal;
