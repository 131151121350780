export const googlePageview = (url: string) => {
  (window as any)?.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
    page_path: url
  });
};

export const googleTagManagerPageView = (url: string) => {
  (window as any)?.dataLayer.push({
    event: 'pageview',
    page: url
  });
};

export const googleEvent = (
  action: string,
  {
    category,
    label,
    value,
    quantity,
    currency
  }: {
    category?: string;
    label?: string;
    value?: number;
    quantity?: number;
    currency?: string;
  }
) => {
  (window as any)?.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
    quantity,
    currency
  });
};
