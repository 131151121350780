export enum DISCOUNT {
  '10%' = '10%',
  '15%' = '15%',
  '20%' = '20%',
  '30%' = '30%',
  '40%' = '40%',
  '50%' = '50%',
  '60%' = '60%',
  '75%' = '75%'
}
