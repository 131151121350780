import { createTheme } from '@material-ui/core/styles';

import palette from './palette';
import overrides from './overrides';
import typography from './typography';
import breakpoints from './breakpoints';

const themeProps = {
  palette,
  overrides,
  typography,
  breakpoints
};

export const theme = createTheme(themeProps);
