import React, { useMemo } from 'react';
import { useRouter } from 'next/router';

import Modal from 'components/ui/Modal';
import { useCartProducts } from 'utils/hooks/useCartProducts';
import { addToCart, substractFromCart, removeFromCart } from 'utils/apollo/vars/cartVar';
import CartProduct from './CartProduct/CartProduct';
import TotalPrice from './TotalPrice';
import CartModalActions from './CartModalActions';
import { useBuyDisabledMessage } from 'utils/hooks/useBuyDisabledMessage';
import events from 'utils/helpers/analytics/events';
import { calculateTotalPrice } from 'utils/helpers/checkout/calculateTotalPrice';

interface CartModalProps {
  open: boolean;
  onClose: () => void;
}

const CartModal: React.FC<CartModalProps> = ({ onClose, open }): JSX.Element => {
  const { push, pathname } = useRouter();

  const { products } = useCartProducts();

  const totalPrice = useMemo(() => calculateTotalPrice(products), [products]);

  const disabledMessage = useBuyDisabledMessage(products, totalPrice);

  const handleCheckout = () => {
    onClose();

    if (pathname !== '/checkout') {
      push('/checkout');

      events.beginCheckOut(totalPrice);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Кошик"
      subtitle="Безкоштовна доставка від 1500 грн!"
      actionsComponent={
        <>
          <TotalPrice price={totalPrice} />

          <CartModalActions
            onClose={onClose}
            onCheckout={handleCheckout}
            disabledMessage={disabledMessage}
          />
        </>
      }
    >
      {products.map((product) => (
        <CartProduct
          key={product.sys.id}
          product={product}
          addToCart={addToCart}
          substractFromCart={substractFromCart}
          removeFromCart={removeFromCart}
        />
      ))}
    </Modal>
  );
};

export default CartModal;
