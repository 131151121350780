import { useMemo } from 'react';

import { MIN_ORDER_PRICE } from 'domain/constants';
import { CartProductWQuantity } from 'domain/types';
import { areAllAvailable } from 'utils/helpers/products/areAllAvailable';

export const useBuyDisabledMessage = (products: CartProductWQuantity[], totalPrice: number) => {
  const message = useMemo(() => {
    if (products.length === 0) return 'Не обрано жодного товару';

    if (!areAllAvailable(products)) return 'Деякого товару немає в наявності';

    if (totalPrice < MIN_ORDER_PRICE)
      return `Мінімальний розмір замовлення: ${MIN_ORDER_PRICE} грн`;

    return '';
  }, [products, totalPrice]);

  return message;
};
