export const facebookPageview = () => {
  (window as any)?.fbq('track', 'PageView');
};

export const facebookEvent = (
  name: string,
  options: {
    content_name?: string;
    content_category?: string;
    content_ids?: string[];
    content_type?: string;
    value?: number;
    currency?: string;
    contents?: string;
  } = {},
  custom: boolean = false
) => {
  (window as any).fbq(custom ? 'trackCustom' : 'track', name, options);
};
