import { DISCOUNT } from 'domain/enums/DISCOUNT';

export const DISCOUNT_MAP = {
  [DISCOUNT['10%']]: 0.1,
  [DISCOUNT['15%']]: 0.15,
  [DISCOUNT['20%']]: 0.2,
  [DISCOUNT['30%']]: 0.3,
  [DISCOUNT['40%']]: 0.4,
  [DISCOUNT['50%']]: 0.5,
  [DISCOUNT['60%']]: 0.6,
  [DISCOUNT['75%']]: 0.75
};
