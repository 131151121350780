export const NAV_LINKS = [
  // {
  //   href: '/new',
  //   title: 'New'
  // },
  {
    href: '/shop',
    title: 'Магазин'
  },
  // {
  //   href: '/sale',
  //   title: 'Sale'
  // },
  {
    href: '/delivery',
    title: 'Доставка та оплата'
  },
  {
    href: '/about-us',
    title: 'Про нас'
  },
  {
    href: '/feedbacks',
    title: 'Відгуки'
  },
  {
    href: '/tracking',
    title: 'Відстежити замовлення'
  }
];
