import { FACEBOOK_EVENT_NAMES } from 'domain/constants/FACEBOOK_EVENT_NAMES';
import { GOOGLE_EVENT_NAMES } from 'domain/constants/GOOGLE_EVENT_NAMES';
import { getNameWithColor } from '../products/getNameWithColor';
import { facebookEvent } from './facebook';
import { googleEvent } from './google';
import { googleAdsConversion } from './googleAds';

const addToCart = (
  category: string,
  name: string,
  color: string | undefined,
  quantity: number,
  price: number
) => {
  try {
    const nameWithColor = getNameWithColor(name, color);

    googleEvent(GOOGLE_EVENT_NAMES.addToCart, {
      category,
      label: getNameWithColor(name, color),
      value: price,
      quantity,
      currency: 'UAH'
    });

    facebookEvent(FACEBOOK_EVENT_NAMES.addToCart, {
      content_category: category,
      content_name: nameWithColor,
      value: price,
      currency: 'UAH',
      contents: String(quantity)
    });
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

const removeFromCart = (
  category: string,
  name: string,
  color: string | undefined,
  quantity: number
) => {
  try {
    const nameWithColor = getNameWithColor(name, color);

    googleEvent(GOOGLE_EVENT_NAMES.removeFromCart, {
      category,
      label: nameWithColor,
      value: quantity
    });

    facebookEvent(
      FACEBOOK_EVENT_NAMES.removeFromCart,
      {
        content_category: category,
        content_name: nameWithColor,
        value: quantity
      },
      true
    );
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

const beginCheckOut = (totalPrice: number) => {
  try {
    googleEvent(GOOGLE_EVENT_NAMES.beginCheckOut, {
      value: totalPrice
    });

    facebookEvent(FACEBOOK_EVENT_NAMES.beginCheckOut, {
      value: totalPrice,
      currency: 'UAH'
    });
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

const checkOutProgress = (step: number) => {
  try {
    googleEvent(GOOGLE_EVENT_NAMES.checkOutProgress, {
      value: step
    });

    facebookEvent(
      FACEBOOK_EVENT_NAMES.checkOutProgress,
      {
        value: step
      },
      false
    );
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

const purchase = (totalPrice: number, paymentMethod: string, id: string) => {
  try {
    googleEvent(GOOGLE_EVENT_NAMES.purchase, {
      value: totalPrice,
      category: paymentMethod
    });

    facebookEvent(FACEBOOK_EVENT_NAMES.purchase, {
      value: totalPrice,
      currency: 'UAH'
    });

    googleAdsConversion(id);
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

const purchaseError = (errorMessage: string) => {
  try {
    googleEvent(GOOGLE_EVENT_NAMES.purchaseError, {
      category: 'Error',
      label: errorMessage
    });

    facebookEvent(
      FACEBOOK_EVENT_NAMES.purchaseError,
      {
        content_category: 'Error',
        content_name: errorMessage
      },
      true
    );
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

const checkoutValidationError = (step: string, errorMessage: string) => {
  try {
    googleEvent(GOOGLE_EVENT_NAMES.checkOutValidationError, {
      category: step,
      label: errorMessage
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

const events = {
  addToCart,
  beginCheckOut,
  checkOutProgress,
  purchase,
  removeFromCart,
  purchaseError,
  checkoutValidationError
};

export default events;
