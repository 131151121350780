import { DISCOUNT_MAP } from 'domain/constants';
import { DISCOUNT } from 'domain/enums';

export const calculatePrice = (price: number, discount: DISCOUNT | null) => {
  if (discount && discount in DISCOUNT) {
    const discountCoef = DISCOUNT_MAP[discount];

    const discountedPrice = price * (1 - discountCoef);

    return Math.ceil(discountedPrice);
  }

  return Math.ceil(price);
};

export const calculatePriceWithQuantity = (
  price: number,
  discount: DISCOUNT | null,
  quantity: number
) => Math.ceil(calculatePrice(price, discount) * quantity);
