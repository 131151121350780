export const COLORS_HEX_MAP = {
  чорний: '#000000',
  рожевий: '#fcc6dd',
  жовтий: '#fee686',
  зелений: '#78b7a6',
  блакитний: '#9cdbe0',
  фіолетовий: '#b3bff1',
  срібний: '#a8a8a7',
  золотий: '#a17c25',
  'рожеве золото': '#c497ab',
  пастельний:
    'linear-gradient(141deg, rgba(244,183,183,1) 0%, rgba(242,217,179,1) 22%, rgba(249,222,145,1) 33%, rgba(134,241,137,1) 51%, rgba(169,249,251,1) 73%, rgba(245,180,230,1) 100%)',
  кольоровий:
    'linear-gradient(141deg, rgba(255,0,0,1) 0%, rgba(255,189,3,1) 23%, rgba(32,181,36,1) 51%, rgba(11,231,236,1) 73%, rgba(255,0,198,1) 100%)',
  бежевий: '#f4e1dc',
  оранжевий: '#dd8b2d',
  бірюзовий: '#0aaba2',
  сірий: '#a4a4a4',
  коричневий: '#6a4e46',
  білий: '#f0f6fd',
  червоний: '#e9120e'
};
