import React from 'react';
import { makeStyles } from '@material-ui/core';

import { DISCOUNT } from 'domain/enums/DISCOUNT';
import { calculatePrice } from 'utils/helpers/products/calculatePrice';

interface Props {
  price: number;
  discountedPrice?: number;
  discount?: DISCOUNT | null;
  className?: string;
  isAvailable?: boolean;
}

const PriceText: React.FC<Props> = ({
  price,
  discountedPrice,
  discount,
  className,
  isAvailable = true
}): JSX.Element => {
  const classes = useStyles();

  if (!isAvailable) return <span className={className}>{price}&nbsp;грн</span>;

  if (typeof discountedPrice === 'number' && discountedPrice !== price)
    return (
      <span className={className}>
        <span className={classes.line}>{price}&nbsp;грн</span> {discountedPrice}
        &nbsp;грн
      </span>
    );

  if (discount)
    return (
      <span className={className}>
        <span className={classes.line}>{price}&nbsp;грн</span> {calculatePrice(price, discount)}
        &nbsp;грн
      </span>
    );

  return <span className={className}>{price}&nbsp;грн</span>;
};

const useStyles = makeStyles((theme) => ({
  line: {
    textDecoration: 'line-through',
    color: theme.palette.secondary.main
  }
}));

export default PriceText;
